var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "cancellation-confirmation-modal",
        centered: "",
        "hide-header": "",
        size: "lg",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  staticClass: "grey-button-outlined mr-2",
                  attrs: {
                    variant: "outline-secondary",
                    "aria-label": "Keep compliance service",
                  },
                  on: { click: _vm.cancelAction },
                },
                [_vm._v("\n        No, Keep\n      ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "custom-blue-button mr-3",
                  attrs: {
                    "aria-label": "Confirm cancellation of compliance service",
                  },
                  on: { click: _vm.confirmAction },
                },
                [_vm._v("\n        Yes, Cancel\n      ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "cancel-header" },
        [
          _c("h5", [_vm._v("\n      Cancellation Confirmation\n    ")]),
          _c("fa-icon", {
            staticClass: "cancel-button",
            attrs: {
              "aria-label": "Close cancellation modal",
              icon: ["fas", "times"],
            },
            on: { click: _vm.cancelAction },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "modal-body" }, [
        _c("p", { staticClass: "warning-text" }, [
          _vm._v(
            "You are about to cancel your Compliance Service and State filing for ..."
          ),
        ]),
        _c(
          "div",
          { staticClass: "service-card" },
          [
            _c(
              "b-row",
              { staticClass: "body-row" },
              [
                _c("b-col", [_vm._v("\n          Company\n        ")]),
                _c("b-col", { staticClass: "right-align" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.companyName) + "\n        "
                  ),
                ]),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "body-row" },
              [
                _c("b-col", [_vm._v("\n          Jurisdiction\n        ")]),
                _c("b-col", { staticClass: "right-align" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.jurisdiction) + "\n        "
                  ),
                ]),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "body-row" },
              [
                _c("b-col", [_vm._v("\n          Reason\n        ")]),
                _c("b-col", { staticClass: "right-align" }, [
                  _vm._v("\n          " + _vm._s(_vm.reason) + "\n        "),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm.refundAmount
          ? _c(
              "div",
              { staticClass: "refund-card" },
              [
                _c(
                  "b-row",
                  { staticClass: "body-row" },
                  [
                    _c("b-col", [_vm._v("\n          Refund\n        ")]),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex flex-wrap align-items-center justify-content-end",
                        attrs: { cols: "12", md: "auto" },
                      },
                      [_c("strong", [_vm._v("$" + _vm._s(_vm.refundAmount))])]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex flex-wrap align-items-center justify-content-end ri",
                        attrs: { cols: "auto", md: "auto" },
                      },
                      [
                        _c("b-img", {
                          staticClass: "mr-2",
                          attrs: { src: _vm.cardLogo(_vm.card.brand) },
                        }),
                        _vm._v(" **" + _vm._s(_vm.card.last4) + "\n        "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c("div", [
          _c("h5", [
            _vm._v(
              "\n        Heads up! Cancelling Compliance will result in...\n      "
            ),
          ]),
          _c("ul", [
            _c("li", [
              _vm._v(
                "\n          You filing your own reports with the state.\n        "
              ),
            ]),
            _c("li", [
              _vm._v(
                "\n          Potential fines if you miss the deadline or file incorrectly.\n        "
              ),
            ]),
            _c("li", [
              _vm._v(
                "\n          One more thing you have to complete.\n        "
              ),
            ]),
          ]),
          _c("span", [
            _vm._v("Our service is hassle free and makes your life easier. "),
            _c("strong", [_vm._v("Do you wish to continue?")]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }