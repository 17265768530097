<template>
  <b-modal
    id="cancellation-confirmation-modal"
    v-model="showModal"
    centered
    hide-header
    size="lg"
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="cancel-header">
      <h5>
        Cancellation Confirmation
      </h5>
      <fa-icon
        class="cancel-button"
        aria-label="Close cancellation modal"
        :icon="['fas', 'times']"
        @click="cancelAction"
      />
    </div>

    <template #modal-footer class="action-buttons justify-content-end">
        <b-button class="grey-button-outlined mr-2"
                  variant="outline-secondary"
                  aria-label="Keep compliance service"
                  @click="cancelAction"
        >
          No, Keep
        </b-button>
        <b-button
          class="custom-blue-button mr-3"
          aria-label="Confirm cancellation of compliance service"
          @click="confirmAction"
        >
          Yes, Cancel
        </b-button>
    </template>

    <div class="modal-body">
      <p class="warning-text">You are about to cancel your Compliance Service and State filing for ...</p>
      <div class="service-card">
        <b-row class="body-row">
          <b-col>
            Company
          </b-col>
          <b-col class="right-align">
            {{ companyName }}
          </b-col>
        </b-row>
        <b-row class="body-row">
          <b-col>
            Jurisdiction
          </b-col>
          <b-col class="right-align">
            {{ jurisdiction }}
          </b-col>
        </b-row>
        <b-row class="body-row">
          <b-col>
            Reason
          </b-col>
          <b-col class="right-align">
            {{ reason }}
          </b-col>
        </b-row>
      </div>
      <div class="refund-card" v-if="refundAmount">
        <b-row class="body-row">
          <b-col>
            Refund
          </b-col>
          <b-col cols="12" md="auto" class="d-flex flex-wrap align-items-center justify-content-end">
            <strong>${{ refundAmount }}</strong>
          </b-col>
          <b-col cols="auto" md="auto" class="d-flex flex-wrap align-items-center justify-content-end ri">
            <b-img :src="cardLogo(card.brand)" class="mr-2" /> **{{card.last4}}
          </b-col>
        </b-row>
      </div>
      <div>
        <h5>
          Heads up! Cancelling Compliance will result in...
        </h5>
        <ul>
          <li>
            You filing your own reports with the state.
          </li>
          <li>
            Potential fines if you miss the deadline or file incorrectly.
          </li>
          <li>
            One more thing you have to complete.
          </li>
        </ul>
        <span>Our service is hassle free and makes your life easier. <strong>Do you wish to continue?</strong></span>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getCardLogo } from '@/common/modules/cc'

export default {
  name: 'CancellationConfirmationModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    companyName: {
      type: String,
    },
    jurisdiction: {
      type: String,
    },
    reason: {
      type: String,
    },
    refundAmount: {
      type: String,
    },
    card: {
      type: Object,
    },
  },
  computed: {
    showModal: {
      get() {
        return this.value
      },
      set(value) {
        if (!value) this.$emit('input', false)
      },
    },
  },
  methods: {
    cancelAction() {
      this.$emit('cancel')
      this.showModal = false
    },
    confirmAction() {
      this.$emit('confirm')
      this.showModal = false
    },
    cardLogo(brand) {
      return getCardLogo(brand)
    },
  },
}
</script>

<style scoped lang="scss">
.cancel-header {
  position: relative;
  width: 100%;
  height: 100%;
  padding: .5rem 0;
  background-color: white !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancel-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -1rem;
  right: -1rem;
  height: 1px;
  background-color: #ddd;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 1rem .5rem .5rem .5rem;
  margin: auto;
}

.service-card, .refund-card {
  width: 100%;
  margin-bottom: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.body-row {
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 1rem 0;
  margin: auto;
  align-items: center;
}

.body-row:last-child {
  border-bottom: none;
}

.warning-text {
  font-weight: 400;
}

.right-align {
  text-align: right;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
}

.grey-button-outlined {
  background-color: #FFFFFF !important;
  border-color: #CECED2 !important;
  border-radius: 5px;
  color: #4E4E52;
}
.grey-button-outlined:hover {
  background-color: #CDCDCD !important;
}

.custom-blue-button {
  background-color: #2E3798 !important;
  border-radius: 5px;
  color: white !important;
}

.custom-blue-button:hover {
  background-color: #1E2B78 !important;
}

.cancel-button:hover {
  cursor: pointer;
}
</style>
